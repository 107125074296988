import BusinessTable from "../scenes/businessTable";
import ClientTable from "../scenes/clientTable";
import Contacts from "../scenes/contacts";
import CreateUser from "../scenes/createUser";
import Dashboard from "../scenes/dashboard";
import DashboardUser from "../scenes/dashboardUser";
import Form from "../scenes/form";
import { FormSuccessBussines, FormSuccessClient, FormSuccessUpdateProposalStatus } from "../scenes/form/success";
import Profile from "../scenes/profile";
import { ProposalsUser } from "../scenes/proposalUser";
import { Proposals } from "../scenes/proposals";
import Red25 from "../scenes/red25";
import Red25Sem from "../scenes/red25sem";
import Red25Tri from "../scenes/red25tri";
import RedCoins from "../scenes/redCoins";
import Redrural from "../scenes/redrural";
import RedScore from "../scenes/redscore";
import Team from "../scenes/team";

export const allRoutes = {
  home: {
    path: "",
    element: <Dashboard />,
  },
  homeUser: {
    path: "",
    element: <DashboardUser/>,
  },
  team: {
    path: "team",
    element: <Team />,
  },
  contacts: {
    path: "contacts",
    element: <Contacts />,
  },
  form: {
    path: "form",
    element: <Form />,
  },
  formSucessBussines: {
    path: "successbussines",
    element: <FormSuccessBussines />,
  },
  formSucessClient: {
    path: "successclient",
    element: <FormSuccessClient />,
  },
  formSucessStatus: {
    path: "successstatus",
    element: <FormSuccessUpdateProposalStatus />,
  },
  red25: {
    path: "red25",
    element: <Red25 />,
  },
  redtri: {
    path: "red25tri",
    element: <Red25Tri />,
  },
  redrural: {
    path: "redrural",
    element: <Redrural />,
  },
  redsafra: {
    path: "red25sem",
    element: <Red25Sem />,
  },
  redscore: {
    path: "redscore",
    element: <RedScore />,
  },
  proposal: {
    path: "proposals",
    element: <Proposals />,
  },
  proposalUser: {
    path: "proposals/user",
    element: <ProposalsUser />,
  },
  profile: {
    path: "profile",
    element: <Profile />
  },
  createUser: {
    path: "createUser",
    element: <CreateUser />
  },
  clientTable: {
    path: "clients",
    element: <ClientTable />
  },
  businessTable: {
    path: "business",
    element: <BusinessTable />
  },
  redCoins: {
    path: "redutoconsultas",
    element: <RedCoins/>
  },
};
