/* eslint-disable array-callback-return */
import { Box, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import * as yup from "yup";
import { useCookiesLogin } from "../../config/cookies";

import { Formik } from "formik";
import { toast } from "react-toastify";
import { greenColor } from "../../config/colors";
import { HOST_SINGED } from "../../config/hosts";
import { tokens } from "../../theme";

import { Backdrop, Button, CircularProgress, TextField } from "@mui/material";

import Header from "../../components/Header";
import HandlerSerasaSocoreCPF from "../../components/SerasaScoreCPF";
import HandlerSerasaSocorePj from "../../components/SerasaScorePj";
import { handleSerasaScore } from "../redsimulation/createConsulting";


const isCPF = (value) => value.length == 11
const isCNPJ = (value) => value.length > 11;

const Consulta = ({ handleRefresh, username }) => {
  
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box>
      {username !== "ouropreto" ? (
        handleRefresh === true ? (
          <Button
            type="submit"
            color="secondary"
            variant="contained"
            onClick={() => {
              window.location.reload();
            }}
          >
            Nova Consulta
          </Button>
        ) : (
          <Button type="submit" color="secondary" variant="contained">
            Consultar cliente
          </Button>
        )
      ) : (
        <Box>
          <Typography
            variant="h5"
            color={colors.grey[100]}
            fontWeight="bold"
            sx={{ m: "0 0 5px 0" }}
          >
            Saldo de consultas indisponível. Por favor, renove para continuar.
          </Typography>
        </Box>
      )}
    </Box>
  );
};


const RedScore = () => {
  const { cookies } = useCookiesLogin();
  const theme = useTheme();
  const [document, setDocument] = useState("");
  const { id, username} = cookies.BRAUM_TOKEN;
  
  const userID = cookies.BRAUM_TOKEN.id;

  const [dataInfo, setDataInfo] = useState(false);
  const [dataAPI, setDataAPI] = useState({});
  const [isClient, setIsClient] = useState(false);
  const [handleRefresh, setHandleRefresh] = useState(false);
  const [tokenSerasa, setTokenSerasa] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const regexCPF = /^(\d{3})(\d{3})(\d{3})(\d{2})$/;
  const regexCNPJ = /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/;

  const initialValues = {
    cpf: "",
    amount: "",
  };

  const verificationCPFCNPJAndAmount = (cpf) => {
    return cpf.replace(/\D/g, "").length === 0;
  };

  useEffect(() => {
    async function getToken() {
      const authSerasa = await fetch(`${HOST_SINGED}/auth/serasa`, {
        method: "GET",
      });

      const returnToken = await authSerasa.json();
      setTokenSerasa(returnToken["token"]);
    }
    getToken();
  }, []);

  const valuesSchema = yup.object().shape({
    amount: yup.number().required("Valor é obrigatório"),
    cpf: yup.string().required("CPF é obrigatório"),
  });

  return (
    <Box p={5}>
      <Box mt={10}>
        <ToastContainer />
        <Header
          title={`RED SCORE`}
          subtitle="Informe o CPF ou CNPJ Rural e tenha acesso a pontuação do SCOREAGRO e Probabilidade de Inadimplência do seu Cliente."
        />
        <Formik initialValues={initialValues} validationSchema={valuesSchema}>
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <form
              onSubmit={async (event) => {
                setIsLoading(true);
                handleSubmit(event);

                if (verificationCPFCNPJAndAmount(values.cpf)) {
                  toast.info("CPF/CNPJ não pode estar vazio", {
                    theme: "dark",
                  });
                  setIsLoading(false);
                }

                const requestToken = await fetch(`${HOST_SINGED}/auth/serasa`, {
                  method: "GET",
                });

                const dataToken = await requestToken.json();

                // check user is already exists in database

                const response = await fetch(
                  `${HOST_SINGED}/client/${values.cpf.replace(/\D/g, "")}`,
                  {
                    method: "GET",
                  }
                );

                if (response.status == 200) {
                  // consulta ficha já existente
                  const dataClientAlreadyExists = await handleSerasaScore(
                    values.cpf.replace(/\D/g, ""),
                    dataToken["token"],
                    userID
                  );
                  console.info(
                    dataClientAlreadyExists,
                    "dataClientAlreadyExists"
                  );
                  setDataAPI(dataClientAlreadyExists);
                }

                // .then((response) => response.json())
                // .then(async (data) => {
                //   if (data.status === false) {
                //     setDataInfo(true);
                //     const dataToken = await requestToken.json();
                //     let infoserasa = await handleSerasaScore(
                //       values.cpf.replace(/\D/g, ""),
                //       dataToken["token"],
                //       userID
                //     );

                //     httpSerasaBasicInfo(
                //       infoserasa._id,
                //       dataToken["token"]
                //     ).then(
                //       (consult) => console.info(consult, "CNPJF")
                //       // aqui desce CPF NOTION
                //     );

                //   }
                //   console.log(data, "data")
                //   console.log(data, "dados!!!");

                //   setIsClient(data.status);
                //   setBussinesId(data.bussines_id);
                //   setClientId(data.client_id);
                // })
                // .then(async () => {
                //   const dataToken = await requestToken.json();

                //   if (values.cpf.replace(/\D/g, "").length === 11) {
                //     const consult_id = await handleSerasaScore(
                //       values.cpf.replace(/\D/g, ""),
                //       dataToken["token"],
                //       userID
                //     );

                //     if (consult_id == null || consult_id == undefined) {
                //       setIsLoading(false);
                //       alert(
                //         "Não foi possivel realizar a consulta, consulte seu consultor"
                //       );
                //     }

                //     console.log(consult_id, "consult_id");
                //     httpSerasaBasicInfo(consult_id._id, dataToken["token"]).then(
                //       (consult) => console.info(consult)
                //       // aqui desce CPF NOTION

                //     );
                //     setIdConsult(consult_id._id);
                //   }

                //   setBrainAuth(dataToken["token"]);
                //   setCpf(values.cpf.replace(/\D/g, ""));
                //   setHandleRefresh(true);
                //   setIsLoading(false);
                // });
                setDocument(values.cpf.replace(/\D/g, ""));
                console.log(response.status, "response");
                console.log(response, "response");
                setIsLoading(false);
              }}
            >
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              >
                <TextField
                  fullWidth
                  variant="filled"
                  label="CPF/CNPJ"
                  type="text"
                  color="secondary"
                  value={
                    values.cpf.length === 11
                      ? values.cpf.replace(regexCPF, "$1.$2.$3-$4")
                      : values.cpf.replace(regexCNPJ, "$1.$2.$3/$4-$5")
                  }
                  onChange={handleChange}
                  handleBlur={handleBlur}
                  inputProps={{ maxLength: 22 }}
                  sx={{ gridColumn: "span 2" }}
                  name="cpf"
                  error={!!touched.cpf && !!errors.cpf}
                  helperText={touched.cpf && errors.cpf}
                />
                <Consulta handleRefresh={handleRefresh} username={username}></Consulta>
              </Box>
            </form>
          )}
        </Formik>

        {console.log(
          dataInfo === false && isClient === true,
          "é cliente, data info false datainfo " +
            dataInfo +
            "cliente: " +
            isClient
        )}

        {/* COMPONENTE RETORNA DIRETO O ID CADASTRADO NA BASE DE DADOS */}
        {/* {console.log(dataInfo === false && isClient === true)} */}
        {isCPF(document) == true && (
          <HandlerSerasaSocoreCPF dataAPI={dataAPI}></HandlerSerasaSocoreCPF>
        )}
        {console.log(isCPF(document))}
        {/* COMPONENTE PARA ESPERAR O WEBHOOK BRAIN */}
        {/* {console.log(dataInfo === true && isClient === false)} */}
        {/* {isCNPJ(document) == true && <p>cnpj</p>} */}
        {console.log(isCNPJ(document))}
        {isCNPJ(document) == true && (
          <HandlerSerasaSocorePj dataAPI={dataAPI}></HandlerSerasaSocorePj>
        )}
        <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
          <CircularProgress color="inherit" sx={{ color: greenColor }} />
        </Backdrop>
      </Box>
    </Box>
  );
};

export default RedScore;
